import axios from '@/jslib/axios'

export const getPolicyList = params=>{
  return axios({
    method: 'get',
    url: '/admin/policy/list',
    params
  })
}
export const getPolicyListExport = params=>{
  return axios({
    method: 'get',
    url: '/admin/policy/list/export',
    responseType: 'blob',
    params
  })
}
export const getPolicyDetail = params=>{
  return axios({
    method: 'get',
    url: '/admin/policy/detail',
    params
  })
}
export const checkCovernote = params=>{
  return axios({
    method: 'get',
    url: '/admin/check/covernote/number',
    params
  })
}
export const checkPolicyNumber = params=>{
  return axios({
    method: 'get',
    url: '/admin/check/policy/number',
    params
  })
}
